import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaGoogle, FaTwitter, FaYoutube } from "react-icons/fa";
import Logo from "../../Assets/logo.png";
import map from "../../Assets/map.png";
import HighlightText from "./HighlightText";
import DefaultButton from "../common/DefaultButton";

const BottomFooter = [
  "Privacy Policy",
  "Cookie Policy",
  "Terms and Conditions",
];
const quickLinks = [
  "Home",
  "About us",
  "Channel Partner",
  "Career",
  "Contact us",
];
const customerService = [
  "JDM Square, IInd Floor, Maruti Kunj Road Bhondsi, Bhondsi, Gurgaon, Haryana - 122102",
  "CUSTOMER SUPPORT",
  "support@jdmhomes.in",
  "for all Enquiry",
];

const Footer = () => {
  return (
    <div className="bg-richblack-800">
      <div className="flex flex-col gap-8 items-center justify-between w-11/12 max-w-maxContent text-richblack-400 leading-6 mx-auto py-10 sm:py-14">
        {/* Main Footer Content */}
        <div className="w-full flex flex-col lg:flex-row pb-5 border-b border-richblack-700">
          <div className="lg:w-[65%] flex flex-col lg:flex-row justify-between lg:border-r lg:border-richblack-700 pl-3 lg:pr-5 gap-5 lg:gap-3">
            {/* Logo and Social Links */}
            <div className="flex flex-wrap sm:flex-nowrap w-full gap-4">
              <div className="flex flex-col gap-3 mb-5 w-full sm:w-[48%]">
                <img
                  src={Logo}
                  alt="LOGO"
                  width={160}
                  height={32}
                  className="object-contain"
                />
                <div className="flex gap-3 text-lg mt-5 sm:mt-10">
                  <FaFacebook className="text-xl sm:text-2xl" />
                  <FaGoogle className="text-xl sm:text-2xl" />
                  <FaTwitter className="text-xl sm:text-2xl" />
                  <FaYoutube className="text-xl sm:text-2xl" />
                </div>
              </div>

              {/* Quick Links */}
              <div className="w-full sm:w-[48%]">
                <h1 className="text-richblack-50 font-semibold text-lg sm:text-[16px]">
                  About Us
                </h1>
                <div className="flex flex-col gap-2 mt-2">
                  {quickLinks.map((ele, index) => (
                    <Link
                      key={index}
                      to={
                        ele === "Home"
                          ? "/"
                          : ele.split(" ").join("-").toLowerCase()
                      }
                      className="text-[14px] sm:text-base hover:text-richblack-50 transition-all duration-200"
                    >
                      {ele}
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex flex-wrap sm:flex-nowrap w-full gap-4">
              {/* Find Us on Google Section */}
              <div className="w-full sm:w-[48%]">
                <h1 className="text-richblack-50 font-semibold text-lg sm:text-[16px]">
                  Find Us on Google
                </h1>
                <img
                  src={map}
                  alt="Map location"
                  className="mt-2 cursor-pointer hover:opacity-90 transition-opacity duration-200 w-[200px] h-[120px] sm:w-full sm:h-auto"
                />
              </div>

              {/* Address Section */}
              <div className="w-full sm:w-[48%]">
                <h1 className="text-richblack-50 font-semibold text-lg sm:text-[16px]">
                  Address
                </h1>
                <div className="flex flex-col gap-2 mt-2">
                  {customerService.map((ele, index) => (
                    <div
                      key={index}
                      className="text-[14px] sm:text-base cursor-pointer hover:text-richblack-50 transition-all duration-200"
                    >
                      <Link to={ele.split(" ").join("-").toLowerCase()}>
                        {ele}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Subscription Section */}
          <div className="lg:w-[35%] flex flex-col justify-between gap-3 pl-3 lg:pl-5 mt-5 lg:mt-0">
            <h4 className="text-2xl sm:text-3xl font-semibold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
              Subscribe us
            </h4>
            <p className="text-sm sm:text-base">
              <HighlightText
                text={
                  "Sign up now and get news about our exclusive offers & latest launches."
                }
              />
            </p>
            <form className="flex items-center space-x-2">
              <input
                required
                type="text"
                name="email"
                placeholder="Enter email address"
                className="w-[70%] sm:w-[80%] p-[10px] sm:p-[12px] rounded-md bg-richblack-600 text-richblack-5"
              />
              <DefaultButton
                text="Subscribe"
                customClasses="w-[30%] sm:w-[20%] justify-center"
              />
            </form>
          </div>
        </div>

        {/* Bottom Footer */}
        <div className="flex flex-col sm:flex-row items-center sm:justify-between w-full text-richblack-400 pb-5 text-sm">
          <div className="flex flex-col sm:flex-row items-center gap-3 w-full">
            {BottomFooter.map((ele, i) => (
              <Link
                key={i}
                to={ele.split(" ").join("-").toLowerCase()}
                className={`px-3 text-center sm:text-left ${
                  i !== BottomFooter.length - 1
                    ? "border-b sm:border-b-0 sm:border-r border-richblack-700"
                    : ""
                } cursor-pointer hover:text-richblack-50 transition-all duration-200`}
              >
                {ele}
              </Link>
            ))}
          </div>
          <div className="text-center mt-4 sm:mt-0">❤️ JMD Homes © 2024</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
