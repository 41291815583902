// import React from "react";
// import Image1 from "../Assets/buildings.png";

// const Home = () => {
//   const Stats = [
//     { count: "500+", label: "Happy Customer" },
//     { count: "1000+", label: "Sq Ft Projects Delivered" },
//     { count: "1000+", label: "Sq Ft Projects Under Planning in next 1 Year" },
//     {
//       count: "2000+",
//       label:
//         "Sq Ft Projects Under Construction & Upcoming Delivery in Next 1 Year",
//     },
//   ];

//   return (
//     <div className="relative mt-0">
//       {/* Image Section */}
//       <img
//         src={Image1}
//         alt="Channel Partner"
//         className="w-full h-[50vh] md:h-[75vh] lg:h-[100vh] object-cover"
//       />

//       {/* Heading on Top of Image */}
//       <div className="absolute top-[10%] md:top-[15%] left-[5%] md:left-[10%] transform -translate-y-1/2 text-3xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
//         <div>Unlock the Door to Future:</div>
//         <div className="text-2xl md:text-4xl mt-2">Start Exploring Today</div>
//       </div>

//       {/* Welcome Section */}
//       <section className="w-full lg:w-[75%] bg-white rounded-lg mb-20 mt-10 md:mt-20 mx-auto p-4 md:p-8">
//         <h2 className="text-center text-2xl md:text-4xl font-bold mb-8 md:mb-12">
//           WELCOME TO JDM HOMES
//         </h2>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-20">
//           {/* Left Side - Dream & Mission */}
//           <div className="space-y-6 md:space-y-8">
//             <div>
//               <h3 className="text-xl md:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
//                 Our Dream
//               </h3>
//               <p className="text-gray-800 mt-2 text-sm md:text-base">
//                 At our real estate firm, we aim to redefine the home-buying
//                 experience by focusing on integrity, personalized service, and
//                 community connection. We leverage innovative technology and
//                 data-driven insights to guide clients through every step of
//                 their journey, ensuring informed decisions and satisfaction. Our
//                 commitment to sustainability and local engagement drives us to
//                 support the neighborhoods we serve, creating lasting
//                 relationships. Ultimately, we aspire to be a trusted partner in
//                 helping clients find not just houses, but homes where they can
//                 thrive.
//               </p>
//             </div>
//             <div>
//               <h3 className="text-xl md:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
//                 Our Mission
//               </h3>
//               <p className="text-gray-800 mt-2 text-sm md:text-base">
//                 Our mission is to provide exceptional real estate services by
//                 leveraging online tools and technology to enhance the client
//                 experience. We prioritize the unique needs of each client,
//                 offering virtual consultations, online property tours, and easy
//                 access to listings, ensuring a seamless process from start to
//                 finish. By utilizing innovative digital platforms and
//                 comprehensive market insights, we empower clients to make
//                 informed decisions with confidence. Additionally, we are
//                 committed to sustainability and actively engage with our
//                 communities to foster positive connections. Ultimately, our
//                 mission is to create meaningful online experiences that lead to
//                 successful outcomes, helping clients find their homes with ease
//                 and comfort.
//               </p>
//             </div>
//           </div>

//           {/* Right Side - Vision */}
//           <div className="space-y-6 md:space-y-8">
//             <div>
//               <h3 className="text-xl md:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
//                 Our Vision
//               </h3>
//               <p className="text-gray-800 mt-2 text-sm md:text-base">
//                 Our vision is to transform the real estate experience by being a
//                 trusted partner in each client’s journey to finding their
//                 perfect home. We aim to lead the industry through a commitment
//                 to integrity, personalized service, and innovative solutions. By
//                 harnessing the latest technology and sustainable practices, we
//                 strive to create seamless experiences that empower clients to
//                 make informed choices. We believe in building strong community
//                 connections and actively participating in local initiatives to
//                 foster a sense of belonging. Ultimately, we envision a future
//                 where our firm not only facilitates property transactions but
//                 also enhances lives, helping everyone find a place they can
//                 truly call home.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Stats Section */}
//       <section className="w-full lg:w-[75%] bg-richblack-700 rounded-lg mb-20 mt-10 md:mt-20 mx-auto p-4 md:p-8">
//         <div className="grid grid-cols-2 md:grid-cols-4 text-center gap-4 md:gap-8">
//           {Stats.map((data, index) => (
//             <div className="flex flex-col py-6 md:py-8" key={index}>
//               <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-richblack-5">
//                 {data.count}
//               </h1>
//               <h2 className="text-sm sm:text-md md:text-lg text-richblack-200">
//                 {data.label}
//               </h2>
//             </div>
//           ))}
//         </div>
//       </section>

//       {/* Unique Section */}
//       <section className="w-full lg:w-[75%] bg-white rounded-lg mb-20 mt-10 md:mt-20 mx-auto p-4 md:p-8">
//         <h2 className="text-center text-2xl md:text-4xl font-bold mb-8 md:mb-12">
//           Why is JDM Homes Unique in Gurgaon?
//         </h2>
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
//           {/* Cards */}
//           {["Value Based Company", "New Approach", "Zero Compromise"].map(
//             (title, index) => (
//               <div
//                 key={index}
//                 className="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] p-4 md:p-6 rounded-lg text-center text-white"
//               >
//                 <h3 className="text-xl md:text-2xl font-bold">{title}</h3>
//               </div>
//             )
//           )}
//         </div>
//       </section>

//       {/* Development Goals Section */}
//       <section className="w-full lg:w-[75%] bg-white rounded-lg mb-20 mt-10 md:mt-20 mx-auto p-4 md:p-8">
//         <h2 className="text-center text-2xl md:text-4xl font-bold mb-4 md:mb-6 bg-clip-text text-transparent bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
//           Our Development Goal
//         </h2>
//         <p className="text-gray-600 text-center text-sm md:text-base px-2 md:px-4">
//           Our real estate firm is focused on boosting market share by 20% in a
//           year through strategic partnerships and community engagement. We aim
//           for a 30% increase in client satisfaction by adopting technologies
//           like virtual tours and enhancing feedback processes. We will engage in
//           four community initiatives annually and implement eco-friendly
//           practices. Each team member will complete one training course yearly,
//           and we're targeting a 25% increase in online engagement through
//           improved digital marketing.
//         </p>
//       </section>

//       {/* Contact Section */}
//       <section className="w-full bg-gray-200 py-8 md:py-12">
//         <div className="container mx-auto px-4">
//           <h1 className="text-3xl md:text-5xl text-orange-500 pt-8 text-center">
//             Get in Touch
//           </h1>
//           <h3 className="text-xl md:text-3xl text-gray-600 capitalize mt-4 text-center">
//             Questions, thoughts, or just want to say hello?
//           </h3>

//           <div className="mt-6 flex justify-center">
//             <form className="flex flex-col gap-4 md:gap-8 w-full max-w-xl mx-4">
//               <input
//                 className="w-full h-10 md:h-12 px-4 text-md md:text-lg font-medium rounded border-none shadow-lg"
//                 type="text"
//                 name="name"
//                 placeholder="Enter your Name"
//               />
//               <input
//                 className="w-full h-10 md:h-12 px-4 text-md md:text-lg font-medium rounded border-none shadow-lg"
//                 type="email"
//                 name="email"
//                 placeholder="Enter your Email Address"
//               />
//               <input
//                 className="w-full h-10 md:h-12 px-4 text-md md:text-lg font-medium rounded border-none shadow-lg"
//                 type="text"
//                 name="subject"
//                 placeholder="Enter your Subject"
//               />
//               <textarea
//                 name="message"
//                 rows="4"
//                 className="w-full p-4 text-md md:text-lg font-medium rounded border-none shadow-lg"
//                 placeholder="Enter your Message"
//               ></textarea>

//               <button
//                 type="submit"
//                 className="bg-pink-500 text-white font-semibold text-md md:text-lg py-2 md:py-3 rounded transition-transform duration-200 hover:scale-90"
//               >
//                 Send Message
//                 <i className="submit-icon fas fa-paper-plane ml-2"></i>
//               </button>
//             </form>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Home;

import React from "react";
import Image1 from "../Assets/buildings.png";

const Home = () => {
  const Stats = [
    { count: "500+", label: "Happy Customer" },
    { count: "1000+", label: "Sq Ft Projects Delivered" },
    { count: "1000+", label: "Sq Ft Projects Under Planning in next 1 Year" },
    {
      count: "2000+",
      label:
        "Sq Ft Projects Under Construction & Upcoming Delivery in Next 1 Year",
    },
  ];

  return (
    <div className="relative mt-0">
      {/* Image Section */}
      <img
        src={Image1}
        alt="Channel Partner"
        className="w-full h-[40vh] sm:h-[50vh] md:h-[75vh] lg:h-[100vh] object-cover"
      />

      {/* Heading on Top of Image */}
      <div className="absolute top-[7%] sm:top-[10%] md:top-[15%] left-[5%] sm:left-[8%] md:left-[10%] transform -translate-y-1/2 text-2xl sm:text-3xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
        <div>Unlock the Door to Future:</div>
        <div className="text-lg sm:text-2xl md:text-4xl mt-1 sm:mt-2">
          Start Exploring Today
        </div>
      </div>

      {/* Welcome Section */}
      <section className="w-full lg:w-[75%] bg-white rounded-lg mb-16 sm:mb-20 mt-8 sm:mt-10 md:mt-20 mx-auto p-3 sm:p-4 md:p-8">
        <h2 className="text-center text-xl sm:text-2xl md:text-4xl font-bold mb-6 sm:mb-8 md:mb-12">
          WELCOME TO JDM HOMES
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-8 md:gap-20">
          {/* Left Side - Dream & Mission */}
          <div className="space-y-4 sm:space-y-6 md:space-y-8">
            <div>
              <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
                Our Dream
              </h3>
              <p className="text-gray-800 mt-2 text-sm sm:text-base md:text-lg">
                At our real estate firm, we aim to redefine the home-buying
                experience by focusing on integrity, personalized service, and
                community connection. We leverage innovative technology and
                data-driven insights to guide clients through every step of
                their journey, ensuring informed decisions and satisfaction. Our
                commitment to sustainability and local engagement drives us to
                support the neighborhoods we serve, creating lasting
                relationships. Ultimately, we aspire to be a trusted partner in
                helping clients find not just houses, but homes where they can
                thrive.
              </p>
            </div>
            <div>
              <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
                Our Mission
              </h3>
              <p className="text-gray-800 mt-2 text-sm sm:text-base md:text-lg">
                Our mission is to provide exceptional real estate services by
                leveraging online tools and technology to enhance the client
                experience. We prioritize the unique needs of each client,
                offering virtual consultations, online property tours, and easy
                access to listings, ensuring a seamless process from start to
                finish. By utilizing innovative digital platforms and
                comprehensive market insights, we empower clients to make
                informed decisions with confidence. Additionally, we are
                committed to sustainability and actively engage with our
                communities to foster positive connections. Ultimately, our
                mission is to create meaningful online experiences that lead to
                successful outcomes, helping clients find their homes with ease
                and comfort.
              </p>
            </div>
          </div>

          {/* Right Side - Vision */}
          <div className="space-y-4 sm:space-y-6 md:space-y-8">
            <div>
              <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
                Our Vision
              </h3>
              <p className="text-gray-800 mt-2 text-sm sm:text-base md:text-lg">
                Our vision is to transform the real estate experience by being a
                trusted partner in each client’s journey to finding their
                perfect home. We aim to lead the industry through a commitment
                to integrity, personalized service, and innovative solutions. By
                harnessing the latest technology and sustainable practices, we
                strive to create seamless experiences that empower clients to
                make informed choices. We believe in building strong community
                connections and actively participating in local initiatives to
                foster a sense of belonging. Ultimately, we envision a future
                where our firm not only facilitates property transactions but
                also enhances lives, helping everyone find a place they can
                truly call home.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="w-full lg:w-[75%] bg-richblack-700 rounded-lg mb-16 sm:mb-20 mt-8 sm:mt-10 md:mt-20 mx-auto p-3 sm:p-4 md:p-8">
        <div className="grid grid-cols-2 md:grid-cols-4 text-center gap-2 sm:gap-4 md:gap-8">
          {Stats.map((data, index) => (
            <div className="flex flex-col py-4 sm:py-6 md:py-8" key={index}>
              <h1 className="text-lg sm:text-xl md:text-3xl font-bold text-richblack-5">
                {data.count}
              </h1>
              <h2 className="text-xs sm:text-sm md:text-lg text-richblack-200">
                {data.label}
              </h2>
            </div>
          ))}
        </div>
      </section>

      {/* Unique Section */}
      <section className="w-full lg:w-[75%] bg-white rounded-lg mb-16 sm:mb-20 mt-8 sm:mt-10 md:mt-20 mx-auto p-3 sm:p-4 md:p-8">
        <h2 className="text-center text-xl sm:text-2xl md:text-4xl font-bold mb-6 sm:mb-8 md:mb-12">
          Why is JDM Homes Unique in Gurgaon?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 sm:gap-4 md:gap-8">
          {/* Cards */}
          {["Value Based Company", "New Approach", "Zero Compromise"].map(
            (title, index) => (
              <div
                key={index}
                className="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] p-3 sm:p-4 md:p-6 rounded-lg text-center text-white"
              >
                <h3 className="text-lg sm:text-xl md:text-2xl font-bold">
                  {title}
                </h3>
              </div>
            )
          )}
        </div>
      </section>

      {/* Development Goals Section */}
      <section className="w-full lg:w-[75%] bg-white rounded-lg mb-16 sm:mb-20 mt-8 sm:mt-10 md:mt-20 mx-auto p-3 sm:p-4 md:p-8">
        <h2 className="text-center text-xl sm:text-2xl md:text-4xl font-bold mb-4 md:mb-6 bg-clip-text text-transparent bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
          Our Development Goal
        </h2>
        <p className="text-gray-600 text-center text-sm sm:text-base md:text-lg px-2 md:px-4">
          Our real estate firm is focused on boosting market share by 20% in a
          year through strategic partnerships and community engagement. We aim
          for a 30% increase in client satisfaction by adopting technologies
          like virtual tours and enhancing feedback processes. We will engage in
          four community initiatives annually and implement eco-friendly
          practices. Each team member will complete one training course yearly,
          and we're targeting a 25% increase in online engagement through
          improved digital marketing.
        </p>
      </section>

      {/* Contact Section */}
      <section className="w-full bg-gray-200 py-6 sm:py-8 md:py-12">
        <div className="container mx-auto px-4">
          <h1 className="text-2xl sm:text-3xl md:text-5xl text-orange-500 pt-8 text-center">
            Get in Touch
          </h1>
          <h3 className="text-md sm:text-lg md:text-3xl text-gray-600 capitalize mt-2 sm:mt-4 text-center">
            Questions, thoughts, or just want to say hello?
          </h3>

          <div className="mt-4 sm:mt-6 flex justify-center">
            <form className="flex flex-col gap-3 sm:gap-4 md:gap-8 w-full max-w-xl mx-4">
              <input
                className="w-full h-10 sm:h-10 md:h-12 px-4 text-sm sm:text-md md:text-lg font-medium rounded border-none shadow-lg"
                type="text"
                name="name"
                placeholder="Enter your Name"
              />
              <input
                className="w-full h-10 sm:h-10 md:h-12 px-4 text-sm sm:text-md md:text-lg font-medium rounded border-none shadow-lg"
                type="email"
                name="email"
                placeholder="Enter your Email Address"
              />
              <input
                className="w-full h-10 sm:h-10 md:h-12 px-4 text-sm sm:text-md md:text-lg font-medium rounded border-none shadow-lg"
                type="text"
                name="subject"
                placeholder="Enter your Subject"
              />
              <textarea
                name="message"
                rows="4"
                className="w-full p-4 text-sm sm:text-md md:text-lg font-medium rounded border-none shadow-lg resize-none"
                placeholder="Enter your Message"
              ></textarea>
              <button
                className="bg-[#833AB4] hover:bg-[#FD1D1D] transition-all duration-300 text-white font-semibold h-10 sm:h-10 md:h-12 text-sm sm:text-md md:text-lg rounded shadow-lg mt-2"
                type="submit"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
