import React, { useState } from "react";

const ChannelPartnerForm = () => {
  const [formData, setFormData] = useState({
    agentName: "",
    email: "",
    companyName: "",
    companyAddress: "",
    phoneNumber: "",
    panCardNumber: "",
    gstNumber: "",
    reraCertificateNumber: "",
    builderName: "",
    projectDetails: "",
    operatingLocations: "",
    propertyInterest: "",
    message: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted", formData);
  };

  return (
    <form
      className="flex flex-col gap-7 w-full mx-auto p-6 border border-gray-300 rounded-lg shadow-md max-w-[900px] mt-10"
      onSubmit={handleOnSubmit}
    >
      <h2 className="text-3xl font-semibold text-center text-gradient bg-clip-text text-transparent bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
        Register as a Channel Partner
      </h2>

      {/* Row: Agent Name and Email */}
      <div className="flex flex-col gap-4 sm:flex-row sm:gap-6">
        <div className="flex flex-col w-full sm:w-1/2">
          <label className="text-sm font-semibold">Agent Name</label>
          <input
            className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="text"
            name="agentName"
            value={formData.agentName}
            onChange={handleOnChange}
            required
          />
        </div>

        <div className="flex flex-col w-full sm:w-1/2">
          <label className="text-sm font-semibold">Email</label>
          <input
            className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleOnChange}
            required
          />
        </div>
      </div>

      {/* Row: Company Name and Company Address */}
      <div className="flex flex-col gap-4 sm:flex-row sm:gap-6">
        <div className="flex flex-col w-full sm:w-1/2">
          <label className="text-sm font-semibold">Company Name</label>
          <input
            className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleOnChange}
            required
          />
        </div>

        <div className="flex flex-col w-full sm:w-1/2">
          <label className="text-sm font-semibold">Company Address</label>
          <input
            className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="text"
            name="companyAddress"
            value={formData.companyAddress}
            onChange={handleOnChange}
            required
          />
        </div>
      </div>

      {/* Row: Phone Number and Pan Card Number */}
      <div className="flex flex-col gap-4 sm:flex-row sm:gap-6">
        <div className="flex flex-col w-full sm:w-1/2">
          <label className="text-sm font-semibold">Phone Number</label>
          <input
            className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleOnChange}
            required
          />
        </div>

        <div className="flex flex-col w-full sm:w-1/2">
          <label className="text-sm font-semibold">Pan Card Number</label>
          <input
            className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="text"
            name="panCardNumber"
            value={formData.panCardNumber}
            onChange={handleOnChange}
            required
          />
        </div>
      </div>

      {/* Row: GST Number and RERA Certificate Number */}
      <div className="flex flex-col gap-4 sm:flex-row sm:gap-6">
        <div className="flex flex-col w-full sm:w-1/2">
          <label className="text-sm font-semibold">GST Number</label>
          <input
            className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="text"
            name="gstNumber"
            value={formData.gstNumber}
            onChange={handleOnChange}
            required
          />
        </div>

        <div className="flex flex-col w-full sm:w-1/2">
          <label className="text-sm font-semibold">
            RERA Certificate Number
          </label>
          <input
            className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="text"
            name="reraCertificateNumber"
            value={formData.reraCertificateNumber}
            onChange={handleOnChange}
            required
          />
        </div>
      </div>

      {/* Builder Name */}
      <div className="flex flex-col gap-2">
        <label className="text-sm font-semibold">
          Name of Builder Currently Associated With
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="text"
          name="builderName"
          value={formData.builderName}
          onChange={handleOnChange}
          required
        />
      </div>

      {/* Row: Project Details and Operating Locations */}
      <div className="flex flex-col gap-4 sm:flex-row sm:gap-6">
        <div className="flex flex-col w-full sm:w-1/2">
          <label className="text-sm font-semibold">
            Details of Project Sold
          </label>
          <input
            className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="text"
            name="projectDetails"
            value={formData.projectDetails}
            onChange={handleOnChange}
            required
          />
        </div>

        <div className="flex flex-col w-full sm:w-1/2">
          <label className="text-sm font-semibold">Operating Locations</label>
          <input
            className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="text"
            name="operatingLocations"
            value={formData.operatingLocations}
            onChange={handleOnChange}
            required
          />
        </div>
      </div>

      {/* Property Interest */}
      <div className="flex flex-col gap-2">
        <label className="text-sm font-semibold">Property Interested In</label>
        <input
          className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="text"
          name="propertyInterest"
          value={formData.propertyInterest}
          onChange={handleOnChange}
          required
        />
      </div>

      {/* Message */}
      <div className="flex flex-col gap-2">
        <label className="text-sm font-semibold">Message</label>
        <textarea
          className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          name="message"
          value={formData.message}
          onChange={handleOnChange}
          rows="4"
          required
        ></textarea>
      </div>

      {/* Submit Button */}
      <div className="flex justify-center">
        <button
          type="submit"
          className="w-full sm:w-[20%] py-3 rounded-md bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] text-white font-bold text-center transition-transform duration-200 hover:scale-95"
        >
          Register
        </button>
      </div>
    </form>
  );
};

export default ChannelPartnerForm;
