import React from "react";
import ContactUsForm from "../Components/ContactUs/ContactUsForm";
import Address from "../Components/ContactUs/Address";
import Map from "../Components/ContactUs/Map";

const ContactUs = () => {
  return (
    <div>
      <div className="mx-auto w-9/12 max-w-maxContent flex flex-col lg:flex-row justify-between gap-10 text-richblack-500 mt-28">
        {/* Left Part - Address */}
        <div className="w-full lg:w-1/2">
          <Address />
        </div>

        {/* Right Part - Contact Us Form */}
        <div className="w-full lg:w-1/2">
          <ContactUsForm />
        </div>
      </div>
      {/* <div className="w-full mt-10">
        <Map />
      </div> */}
    </div>
  );
};

export default ContactUs;
