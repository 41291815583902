import { useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link, matchPath, useLocation } from "react-router-dom";
import logo from "../../Assets/logo.png";
import { NavbarLinks } from "../../data/NavbarLinks";

function Navbar() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isPartner = location.pathname === "/channel-partners";

  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [transparentNavbar, setTransparentNavbar] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Toggle navbar visibility on scroll direction
      setShowNavbar(currentScrollY <= lastScrollY || currentScrollY < 50);
      setLastScrollY(currentScrollY);

      // Toggle transparency based on scroll position
      setTransparentNavbar(currentScrollY < 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const matchRoute = (route) => matchPath({ path: route }, location.pathname);

  return (
    <div
      className={`fixed top-0 w-full z-50 transition-transform duration-300 ${
        showNavbar ? "translate-y-0" : "-translate-y-full"
      } flex h-20 items-center justify-center border-b-[1px] ${
        transparentNavbar ? "bg-transparent" : "bg-white"
      } ${transparentNavbar ? "" : "border-b-richblack-25"}`}
    >
      <div className="flex w-11/12 max-w-[1200px] items-center justify-between">
        <Link to="/">
          <img
            src={logo}
            alt="Logo"
            width={140}
            height={28}
            loading="lazy"
            className="mt-5 sm:mt-4"
          />
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:block">
          <ul className="flex gap-x-4 sm:gap-x-6 lg:gap-x-8">
            {NavbarLinks.map((link, index) => (
              <li key={index}>
                <Link to={link?.path}>
                  <p
                    className={`${
                      matchRoute(link?.path)
                        ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]"
                        : `${
                            (isHomePage || isPartner) && transparentNavbar
                              ? "text-white"
                              : "text-black"
                          } font-medium`
                    } transition-all duration-300 hover:underline text-sm sm:text-base`}
                  >
                    {link.title}
                  </p>
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        {/* Mobile Menu Button */}
        <button
          className="mr-2 sm:mr-4 md:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? (
            <AiOutlineClose fontSize={20} fill="#AFB2BF" />
          ) : (
            <AiOutlineMenu fontSize={20} fill="#AFB2BF" />
          )}
        </button>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="absolute top-16 left-0 right-0 bg-white p-4 sm:p-6 shadow-lg md:hidden">
            <ul className="flex flex-col gap-3 sm:gap-4">
              {NavbarLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link?.path}
                    className="block text-black text-base sm:text-lg font-medium"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
