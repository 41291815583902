import React from "react";
import Image1 from "../Assets/hands.png";
import ChannelPartnerForm from "../Components/ChannelPartner/ChannelPartnerForm";

const ChannelPartner = () => {
  return (
    <div className="relative mt-0">
      {/* Image Section */}
      <img
        src={Image1}
        alt="Channel Partner"
        className="w-full h-[50vh] md:h-[70vh] object-cover"
      />

      {/* Heading on Top of Image */}
      <h1 className="absolute top-[20%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold text-white text-center px-2">
        CHANNEL PARTNERS
      </h1>

      {/* Form Section */}
      <div className="p-4 md:p-8 lg:p-12">
        <ChannelPartnerForm />
      </div>
    </div>
  );
};

export default ChannelPartner;
