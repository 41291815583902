import React from "react";
import { FiMapPin } from "react-icons/fi";

const Address = () => {
  return (
    <div className="flex flex-col items-center p-6 lg:p-8 bg-gray-100">
      {/* Big Heading - Company Name */}
      <h1 className="text-3xl lg:text-5xl font-bold mb-4 lg:mb-6 text-gradient bg-clip-text text-transparent bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] text-center">
        JDM Homes Pvt. Ltd.
      </h1>

      {/* Corporate Office Heading with Location Icon and Address */}
      <div className="text-center">
        <div className="flex justify-center items-center mb-3">
          <FiMapPin className="text-2xl lg:text-3xl mr-2" />
          <h2 className="text-2xl lg:text-3xl font-semibold">
            Corporate Office
          </h2>
        </div>
        <p className="text-sm lg:text-lg mb-4 lg:mb-6">
          JDM Square, IInd Floor, Maruti Kunj Road
          <br />
          Bhondsi, Bhondsi, Gurgaon,
          <br />
          Haryana - 122102
        </p>

        {/* Email */}
        <h2 className="text-xl lg:text-2xl font-semibold mb-2 lg:mb-3">
          Email
        </h2>
        <p className="text-sm lg:text-lg mb-4 lg:mb-6">support@jdmhomes.in</p>

        {/* Phone Numbers */}
        <h2 className="text-xl lg:text-2xl font-semibold mb-2 lg:mb-3">
          Phone Number
        </h2>
        <p className="text-sm lg:text-lg mb-1">+91 99995 80009</p>
      </div>
    </div>
  );
};

export default Address;
