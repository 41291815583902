import React from "react";

export default function DefaultButton({
  text,
  onClick,
  children,
  disabled,
  outline = false,
  customClasses = "",
  type = "button",
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`flex items-center px-4 py-2 font-semibold  ${customClasses} rounded-lg transition-all ${
        outline
          ? "border-2 border-gray-300 text-gray-700 bg-transparent hover:bg-gradient-to-r hover:from-indigo-500 hover:via-purple-500 hover:to-pink-500 hover:text-white"
          : "bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white hover:from-purple-500 hover:via-pink-500 hover:to-red-500"
      } ${
        disabled ? "opacity-50 cursor-not-allowed" : "hover:shadow-lg"
      } ${customClasses}`}
    >
      {children ? (
        <>
          {text}
          {children}
        </>
      ) : (
        text
      )}
    </button>
  );
}
