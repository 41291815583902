export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About us",
    path: "/about-us",
  },
  {
    title: "Channel Partners",
    path: "/channel-partners",
  },
  {
    title: "Career",
    path: "/career",
  },
  {
    title: "Contact us",
    path: "/contact-us",
  },
];
