import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function LongDialog({ setShowDialog }) {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 640); // Adjust the width as needed
    handleResize(); // Check screen size on component mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleAgree = () => {
    setShowDialog(false);
    navigate("/");
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="bg-white w-full md:w-[80%] lg:w-[50%] max-h-[90%] overflow-auto rounded-lg p-4 flex flex-col">
        <div className="flex-grow overflow-auto flex items-center justify-center mb-4">
          <p className="font-normal leading-relaxed text-gray-700 text-center px-2">
            {isSmallScreen ? (
              <>
                By accessing this site, you agree to our disclaimer. JDM Homes
                reserves the right to modify information on this website at any
                time. For accurate project details, please contact our office.
                We are not liable for any claims arising from the website's
                content. <br />
                <br />
                The contents displayed on our website are only for general
                informational purposes. The photographs/imagery used on the
                website and brochures are indicative and artistic impressions.
                <br />
                <br />
                You are advised to contact our head office to understand the
                details regarding a Project and only after thorough
                understanding about the same and taking appropriate advice in
                relation to the Project. While all efforts have been made to
                ensure the accuracy, we make no representations about any of the
                informationcontained in the Website.
                <br />
                Thank you for understanding.
              </>
            ) : (
              <>
                By accessing the website you agree with the disclaimer without
                any qualification or limitation. This is the official website of
                JDM Homes, thereafter referred to as "JDM Homes": the contents
                of which are for general informational purposes only and do not
                constitute a legal offering. JDM Homes reserves the right to
                add, alter, or delete material from the Website at any time
                without prior notice. The particulars contained on the Website
                mention details of Projects/developments undertaken by JDM
                Homes.
                <br />
                <br />
                The contents displayed on our website are only for general
                informational purposes. The photographs/imagery used on the
                website and brochures are indicative and artistic impressions.
                Photographs of interiors, surrounding views, and locations may
                have been digitally enhanced or altered and do not represent
                actual views or surrounding views.
                <br />
                <br />
                You are advised to contact our head office to understand the
                details regarding a Project and only after thorough
                understanding about the same and taking appropriate advice, take
                further decision regarding/in relation to the Project. While all
                efforts have been made to ensure the accuracy of the contents
                herein, we make no representations about any of the information
                contained in the Website. In no event shall JDM Homes, its
                affiliates, employees, directors, and/or officers be made liable
                for any expense, loss or damage, claim arising out of or in
                connection with the use of this Website.
                <br />
                <br />
                Thank you for your understanding.
              </>
            )}
          </p>
        </div>

        {/* Modal Footer */}
        <div className="flex justify-center border-t pt-2">
          <button
            onClick={handleAgree}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Agree
          </button>
        </div>
      </div>
    </div>
  );
}
