import React from "react";
import image from "../Assets/dummy-image.png";

const AboutUs = () => {
  const directors = [
    { name: "Sachin Kumar", avatar: image },
    { name: "Jitesh Kumar", avatar: image },
  ];

  return (
    <div className="mt-28 mb-12 px-4 sm:px-8">
      <h1 className="text-center text-3xl sm:text-5xl font-bold mb-12 sm:mb-16">
        OUR MANAGEMENT
      </h1>

      <div className="flex flex-col sm:flex-row sm:flex-wrap justify-center gap-10 sm:gap-16">
        {directors.map((director, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-2xl p-8 sm:p-12 w-full sm:w-80 lg:w-96 h-auto flex flex-col items-center transition-transform transform hover:scale-105 duration-300"
          >
            <img
              src={director.avatar}
              alt={director.name}
              className="w-32 h-32 sm:w-40 sm:h-40 mb-6 sm:mb-8 rounded-full"
            />
            <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mb-4 text-center">
              {director.name}
            </h2>
            <button className="mt-4 sm:mt-6 bg-blue-500 text-white font-medium py-2 px-6 sm:py-3 sm:px-8 rounded-lg transition-colors duration-200 hover:bg-blue-600">
              View Profile
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;
