import React, { useState } from "react";

const SubmitCvForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    department: "",
    experience: "",
    cvFile: null,
  });

  const { fullName, email, mobileNumber, department, experience, cvFile } =
    formData;

  const handleOnChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted", formData);
  };

  return (
    <form
      className="flex flex-col gap-7 w-full mx-auto p-6 border border-gray-300 rounded-lg shadow-md max-w-lg"
      onSubmit={handleOnSubmit}
    >
      <h2 className="text-3xl font-semibold text-left text-gradient bg-clip-text text-transparent bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
        Submit Your CV
      </h2>

      {/* Full Name */}
      <div className="flex flex-col gap-2">
        <label className="text-sm font-semibold">Full Name</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="text"
          name="fullName"
          value={fullName}
          onChange={handleOnChange}
          required
        />
      </div>

      {/* Email */}
      <div className="flex flex-col gap-2">
        <label className="text-sm font-semibold">Email Address</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="email"
          name="email"
          value={email}
          onChange={handleOnChange}
          required
        />
      </div>

      {/* Mobile Number */}
      <div className="flex flex-col gap-2">
        <label className="text-sm font-semibold">Mobile Number</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="text"
          name="mobileNumber"
          value={mobileNumber}
          onChange={handleOnChange}
          required
        />
      </div>

      {/* Department */}
      <div className="flex flex-col gap-2">
        <label className="text-sm font-semibold">Department</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="text"
          name="department"
          value={department}
          onChange={handleOnChange}
          required
        />
      </div>

      {/* Experience in Years */}
      <div className="flex flex-col gap-2">
        <label className="text-sm font-semibold">Experience (in years)</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="number"
          name="experience"
          value={experience}
          onChange={handleOnChange}
          required
        />
      </div>

      {/* Upload CV */}
      <div className="flex flex-col gap-2">
        <label className="text-sm font-semibold">Upload Your CV</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          type="file"
          name="cvFile"
          onChange={handleOnChange}
          required
        />
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="w-[40%] py-3 rounded-md bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] text-white font-bold text-center transition-transform duration-200 hover:scale-95"
      >
        Submit CV
      </button>
    </form>
  );
};

export default SubmitCvForm;
