import React from "react";
import SubmitCvForm from "../Components/Career/SubmitCvForm ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Career = () => {
  return (
    <div className="mx-auto flex w-9/12 max-w-maxContent flex-col justify-between gap-10 text-richblack-500 m-20">
      {/* Founding Story */}
      <div className="flex flex-col items-center gap-10 lg:flex-row justify-between ">
        {/* left part */}
        <div className="my-24 flex lg:w-[40%] flex-col gap-10 lg:-mt-10">
          {" "}
          {/* Negative margin applied here */}
          <h1 className="mt-20 bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%] ">
            Careers
          </h1>
          <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
            As a company, we recognize the critical role that computer science
            engineers play in our data management efforts within the Real Estate
            sector. Our commitment to leveraging data effectively drives us to
            build a team of skilled engineers who can develop and maintain
            advanced data system. By implementing robust data analytics and
            management solutions, we empower our agents with actionable insights
            into market trends and client needs, enhancing dicision-making and
            client services.
          </p>
          <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
            Our engineers work on integrating innovative technologies , such as
            machine learning and AI, to improve property recommendations and
            streamline operations. Ultimately, our focus on data management not
            only boost our operational efficiency but also strenthens our
            mission to provide exceptional real estate experiences for our
            clients.
          </p>
          <h2 className="text-2xl">Connect with us on</h2>
          {/* LinkedIn Logo */}
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline flex items-center space-x-2"
          >
            {/* <FontAwesomeIcon icon={faLinkedin} size="2x" /> */}
            <span className="text-4xl font-semibold">Linked</span>
            <FontAwesomeIcon icon={faLinkedin} size="3x" />
          </a>
        </div>
        {/* right part */}
        <div className="lg:w-[40%] w-full mt-10">
          <SubmitCvForm />
        </div>
      </div>
    </div>
  );
};

export default Career;
