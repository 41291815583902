import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/common/Navbar";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ChannelPartner from "./pages/ChannelPartner";
import Career from "./pages/Career";
import ContactUs from "./pages/ContactUs";
import Footer from "./Components/common/Footer";
import { LongDialog } from "./Components/common/LongDialog"; // Adjust the path as necessary

function App() {
  const [showDialog, setShowDialog] = useState(true); // Initial state to show the dialog

  return (
    <div className="w-screen min-h-screen bg-white flex flex-col font-inter">
      {showDialog && <LongDialog setShowDialog={setShowDialog} />}{" "}
      {/* Pass setShowDialog prop */}
      {!showDialog && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/channel-partners" element={<ChannelPartner />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      {!showDialog && <Footer />}
    </div>
  );
}

export default App;
